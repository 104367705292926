import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Table } from "semantic-ui-react";

const Newletters = () => {
  const data = useStaticQuery(graphql`
    query newslettersPageQuery {
      allGoogleSheet {
        edges {
          node {
            newsletters {
              title
              link
            }
          }
        }
      }
    }
  `);

  const pageData = data?.allGoogleSheet?.edges?.[0]?.node?.newsletters;

  return (
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Download Link</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {pageData?.map((data, i) => {
            if (data?.link) {
              return (
                <Table.Row>
                  <Table.Cell>{data?.title}</Table.Cell>
                  <Table.Cell>
                    <a
                      href={data.link}
                      target="_blank"
                      rel="noreferrer"
                      download
                    >
                      Link
                    </a>
                  </Table.Cell>
                </Table.Row>
              );
            }
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

export default Newletters;
