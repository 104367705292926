import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import { Container, Spacer } from "ui-start";
import { FullScreenImage, HeadlineCopy, HeadlineWrapper } from ".";
import Layout from "../components/layout";
import Newletters from "../components/newletters";
import {
  StyledPageCopy,
  StyledPageHeading,
  StyledPageSubHeading,
  Wrapper,
} from "../components/styles";
import { HeroImage } from "../components/heroImage";

const heroImage =
  "https://secureservercdn.net/160.153.138.143/pxg.aec.myftpupload.com/wp-content/themes/ELSA/images/slider-img-3.jpg";

const Newsletter = () => {
  const [formValues, setFormValues] = useState();
  const [formSuccess, setFormSuccess] = useState(false);

  const handleSetFormValues = (e) => {
    setFormValues({
      ...formValues,
      [e.target.getAttribute("name")]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("https://sheetdb.io/api/v1/swxlr42wquz8p?sheet=newsletter", {
      method: "POST",
      body: JSON.stringify({
        data: [formValues],
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    setFormSuccess(true);
  };

  return (
    <Layout>
     <HeroImage page={'newsletter'} />
      <Wrapper>
        <Spacer height={32} />
        <StyledPageHeading>Sign up to our Newsletter</StyledPageHeading>
        <Spacer height={32} />
        <Container>
          <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="Name"
                placeholder="name"
                name="name"
                onChange={handleSetFormValues}
              />
              <Form.Input
                fluid
                label="Email"
                placeholder="Email"
                name="email"
                onChange={handleSetFormValues}
              />
              <Form.Input
                fluid
                label="Business (optional)"
                placeholder="Business"
                name="business"
                onChange={handleSetFormValues}
              />
            </Form.Group>

            <Form.Button type="submit">Submit</Form.Button>
            {formSuccess && (
              <StyledPageCopy>
                Many thanks for susbscribing to our newsletter
              </StyledPageCopy>
            )}
          </Form>
        </Container>
        <Spacer height={16} />
        <StyledPageSubHeading>Newsletters</StyledPageSubHeading>
        <Spacer height={16} />
        <Newletters />
      </Wrapper>
    </Layout>
  );
};

export default Newsletter;
